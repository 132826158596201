import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import Head from "../components/head/head";

const DeleteAccount = () => {
  return (
    <>
      <Head
        title={"How to Delete Apebuzz Account"}
        description={"This is how you can delete your apebuzz account"}
      />
      <Box maxWidth={"800px"} height={"100%"} margin={"auto"} pb={4} pt={2}>
        <Container maxWidth="md" sx={{ mt: 5 }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Permanently Delete Your Apebuzz Account
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>1:</strong> Click on <strong>Settings</strong> under
                    the side menu after logging into the Apebuzz application.
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>2:</strong> Click on <strong>Delete Account</strong>
                    , a popup will come up for confirmation of account deletion.
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>3:</strong> Please click on{" "}
                    <strong>Delete Account</strong>, and your account will be
                    deleted immediately.
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>

            <Box
              sx={{
                mt: 3,
                p: 2,
                backgroundColor: "#ffe6e6",
                borderLeft: "4px solid #FF7518",
              }}
            >
              <Typography variant="body2">
                <strong>NOTE:</strong> When you delete your account, your
                profile, photos, videos, comments, likes, and followers will be
                permanently removed.
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default DeleteAccount;
